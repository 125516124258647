// components/Home.tsx
import React from 'react';

const Home: React.FC = () => {
    return (
        <div className="container">
            <header>
                <h1>问题不大</h1>
            </header>
            <br/>
            <main>
                <section id="home">
                    <h2>欢迎来到我们的公司</h2>
                    <p>了解我们能为您提供什么。</p>
                </section>
            </main>

            <br/>
            <footer>
                <p>&copy; {new Date().getFullYear()} 成都问题不大网络科技有限公司
                    保留所有权利 </p>
                <p><a href="https://beian.miit.gov.cn/">备案号：蜀ICP备2024053347号-1</a></p>
            </footer>
        </div>
    );
}
export default Home;
