import React from "react";

const About: React.FC = () => {
    return (
        <div className="About-container">
            <h1>Welcome to the about Page</h1>
            {/* Add content specific to the home page */}
        </div>
    );
};
export default About;
